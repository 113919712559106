<template>
<div class="big-container">
    <section class="section-aliados-quac" id="section-aliados-quac">
        <div class="container-base">
            <div class="container-info-aliados">
                <img src="@/assets/decorador-title-aliados.svg" style="max-width: 390px" alt="decorador titulo quac">
                <h2 class="title-section"><span>Comercios</span> aliados</h2>
                <p>
                    Conoce todos nuestros comercios aliados en los que puedes realizar compras con tu Crédito QUAC en tiendas físicas o tiendas online.
                </p>
            </div>
            <div class="col">
                <img
                    src="@/assets/img-ntiendas-quac.png"
                    style="max-width: 413px; margin-bottom: auto;"
                    alt="imagen de regalos quac!"
                >
            </div>
            <div class="container-aliados">
                <div class="conatiner-aliado-quac">
                    <a href="https://stirpe.co/">
                        <img src="../../assets/aliados/aliado-stirpe.png" alt="">
                        <p class="aliado-name">Stirpe</p>
                        <p class="aliado-type">Moda</p>
                    </a>
                </div>
                <div class="conatiner-aliado-quac">
                    <a href="https://cloverbolsos.co/">
                        <img src="../../assets/aliados/aliado-clover.png" alt="">
                        <p class="aliado-name">Clover</p>
                        <p class="aliado-type">Bolsos</p>
                    </a>
                </div>
                <div class="conatiner-aliado-quac">
                    <a href="https://derek.co/">
                        <img src="../../assets/aliados/aliado-derek.png" alt="">
                        <p class="aliado-name">Derek</p>
                        <p class="aliado-type">Moda femenina</p>
                    </a>
                </div>
                <div class="conatiner-aliado-quac">
                    <img src="../../assets/aliados/aliado-u82.png" alt="">
                    <p class="aliado-name">U82 Shoes</p>
                    <p class="aliado-type">Calzado</p>
                </div>
                <div class="conatiner-aliado-quac">
                    <img src="../../assets/aliados/aliado-travel.png" alt="">
                    <p class="aliado-name">Travel</p>
                    <p class="aliado-type">Moda</p>
                </div>
                <div class="conatiner-aliado-quac">
                    <img src="../../assets/aliados/aliado-equis.png" alt="">
                    <p class="aliado-name">Equis</p>
                    <p class="aliado-type">Moda</p>
                </div>
                <div class="conatiner-aliado-quac">
                    <img src="../../assets/aliados/aliado-chevignon.png" alt="">
                    <p class="aliado-name">Chevignon <span>(Bucaramanga)</span></p>
                    <p class="aliado-type">Moda</p>
                </div>
                <div class="conatiner-aliado-quac">
                    <img src="../../assets/aliados/aliado-americanino.png" alt="">
                    <p class="aliado-name">Americanino <span>(Bucaramanga)</span></p>
                    <p class="aliado-type">Moda</p>
                </div>
                <div class="conatiner-aliado-quac">
                    <a href="https://bambinospuppies.com/">
                        <img src="../../assets/aliados/aliado-bambinos.png" alt="">
                        <p class="aliado-name">Bambinos pupies</p>
                        <p class="aliado-type">Mascotas</p>
                    </a>
                </div>
                <div class="conatiner-aliado-quac">
                    <a href="https://quintadelmar.co/">
                        <img src="../../assets/aliados/aliado-quinta.png" alt="">
                        <p class="aliado-name">Quinta del mar</p>
                        <p class="aliado-type">Viajes</p>
                    </a>
                </div>
            </div>
            <!-- <div class="container-carousel-marcas">
                <img
                    src="@/assets/img-bg-tel.svg"
                    class="img-fondo-quac"
                    alt="imagen del fondo app quac"
                >
                <div class="swiper mySwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="@/assets/img-stirpe-carousel.png" alt="">
                        </div>
                        <div class="swiper-slide">
                            <img src="@/assets/img-derek-carousel.png" alt="">
                        </div>
                        <div class="swiper-slide">
                            <img src="@/assets/img-clover-carousel.png" alt="">
                        </div>
                        <div class="swiper-slide">
                            <img src="@/assets/img-bambinos-carousel.png" alt="">
                        </div>
                        <div class="swiper-slide">
                            <img src="@/assets/img-quinta-carousel.png" alt="">
                        </div>
                    </div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div> -->
        </div>
        <div class="decorador-circle" />
    </section>
    <download-app/>
</div>
</template>
<script>
import DownloadApp from './DownloadApp.vue';
export default {
  components: { DownloadApp },
    name: 'section-aliados',
    mounted() {
        const swiperAliados = new window.Swiper(".mySwiper", {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 30,
            grabCursor: false,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        if (swiperAliados) {
            console.log('Loaded swiper')
        }
    },
}
</script>
<style lang="scss">
.big-container {
    background-image: url("../../assets/aliados/bg-aliados.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10% right;
}
.section-aliados-quac {
    position: relative;
    padding: 1rem 10%;
    margin-bottom: 2rem;
    .container-base {
        position: relative;
        z-index: 1;
        display: grid;
        align-items: flex-end;
        grid-template-columns: 7fr 5fr;
        gap: 20px;
        .container-info-aliados {
            z-index: 1;
            .title-section {
                position: relative;
            }
            img {
                width: 100%;
            }
            p {
                margin-bottom: 1.5rem;
            }
        }
        .container-carousel-marcas {
            background: url('../../assets/bg-aliados.svg');
            background-size: cover;
            background-position: bottom right;
            background-repeat: no-repeat;
            border-radius: 20px;
            box-shadow: 0 3px 6px #00000020;
            overflow: scroll;
            position: relative;
            aspect-ratio: 62/56;
            .img-fondo-quac {
                position: absolute;
                top: calc(50% + 10px);
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60%;
            }
            .mySwiper {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                .swiper-button-disabled {
                    opacity: 0;
                }
                .swiper-button-prev, .swiper-button-next {
                    transition: all .2s linear;
                }
                .swiper-button-prev {
                    // left: auto;
                    // right: 10px;
                    // transform: rotate(180deg);
                    color: black;
                    background-color: #f6f6f6;
                    &::after {
                        font-size: 32px;
                        font-weight: bold;
                    }
                }
                .swiper-button-next {
                    // right: auto;
                    // left: 10px;
                    // transform: rotate(180deg);
                    color: black;
                    background-color: #f6f6f6;
                    &::after {
                        font-size: 32px;
                        font-weight: bold;
                    }
                }
                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            width: 100%;
                            padding-right: .5rem;
                            padding-left: .5rem;
                        }
                    }
                }
            }
        }
        .container-aliados {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;
            grid-area: 2/1/2/3;
            .conatiner-aliado-quac {
                max-width: 260px;
                img {
                    width: 100%;
                    margin-bottom: .7rem;
                }
                .aliado {
                    &-name {
                        margin-bottom: 0;
                        text-align: start;
                        font-weight: bold;
                        color: #333333;
                        span {
                            font-weight: normal;
                            font-size: 15px;
                        }
                    }
                    &-type {
                        margin-bottom: 0;
                        text-align: start;
                        color: #727997;
                    }
                }
            }
        }
    }
    .decorador-circle {
        width: 294px;
        height: 294px;
        left: -160px;
        top: 0;
    }
    @media (max-width: 800px) {
        margin-bottom: 0;
        text-align: center;
        .container-base {
            display: block;
            text-align: start;
            .container-carousel-marcas {
                background: none;
                box-shadow: none;
                .mySwiper {
                    .swiper-button-prev, .swiper-button-next {
                        display: none;
                    }
                }
            }
            .container-aliados {
                margin-top: 2rem;
                justify-content: center;
            }
        }
    }
    @media (max-width: 674px) {
        .container-base {
            .container-aliados {
                .conatiner-aliado-quac {
                    max-width: 300px;
                }
            }
        }
    }
}
</style>